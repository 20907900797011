/* @font-face {
    font-family: "College";
    font-weight: normal;
    src: local("College"), url(./fonts/college.woff) format("woff"), url(./fonts/college.ttf) format("truetype");
} */
/* @font-face {
    font-family: "Impact";
    font-weight: normal;
    src: local("Impact"), url(./fonts/impact.woff) format("woff"), url(./fonts/impact.ttf) format("truetype");
}
@font-face {
    font-family: "Sweet Sensation";
    font-weight: normal;
    src: local("Sweet Sensation"), url(./fonts/Sweet\ Sensations.woff2) format("woff2"),
        url(./fonts/Sweet\ Sensations.ttf) format("truetype");
}
@font-face {
    font-family: "Flames";
    font-weight: normal;
    src: local("Flames"), url(./fonts/WhoIsHotStrokeRegular.woff2) format("woff2"),
        url(./fonts/WhoIsHotStrokeRegular.ttf) format("truetype");
} */

*,
*::before,
*::after {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family: "Poppins", sans-serif;
}
body {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

.main-container {
    display: grid;
    grid-template-columns: 1fr 10fr 4fr;
    height: 100vh;
}

.main-container div.left {
    display: flex;
    justify-content: center;
    flex-direction: column;
    z-index: 2;
    background-color: white;
}
.main-container div.left > div {
    display: flex;
    flex-direction: column;
    text-align: center;
    align-items: center;
    cursor: pointer;
    user-select: none;
    padding-block: 0.5rem;
}
.main-container div.left > div:where(:hover, :focus) {
    transition: background-color 600ms ease-out, color 200ms linear;
    background-color: #251d3a;
    color: white;
}
.main-container div.left > div + div {
    margin-top: 1rem;
}

.main-container div.right {
    display: grid;
    grid-template-columns: 1fr 4fr;
    z-index: 2;
    height: 100%;
    background-color: white;
}
.main-container div.right-container {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}
.main-container div.right-container button.export-btn {
    padding: 12px;
    font-size: 1.2rem;
    font-weight: 500;
    background-color: #251d3a;
    color: white;
    border: none !important;
    outline: none !important;
    box-shadow: none !important;
    user-select: none;
    cursor: pointer;
}

.main-container div.right div.tabs {
    display: flex;
    flex-direction: column;
    background-color: #c6d5ea80;
}

.main-container div.right div.tabs button.tab {
    border: none;
    padding: 0.7rem 0.2rem;
    font-size: 1.5rem;
    outline: none !important;
    box-shadow: none !important;
    background-color: transparent;
    cursor: pointer;
}
.main-container div.right div.tabs button.tab:where(:hover, :focus) {
    background-color: rgb(255, 30, 90);
    color: white;
    transition: background-color 600ms ease-out, color 200ms linear;
}
.main-container div.right div.tabs button.tab.selected {
    background-color: rgb(255, 30, 90);
    color: white;
}

.main-container div.right div.tabs button.tab .svg-icon svg {
    width: 20px;
    height: 20px;
}

.main-container div.right div.tab-content div.tab-header {
    height: 50px;
    display: flex;
    background-color: #251d3a;
    color: white;
    align-items: center;
    padding-left: 1rem;
    gap: 1rem;
}

.main-container div.right div.tab-content div.tab-body div.svgColorAmounts {
    display: flex;
    flex-wrap: wrap;
}
.main-container div.right div.tab-content div.tab-body div.svgColorAmounts .amount-btn {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    width: 50%;
    height: 100px;
    padding: 10px;
    user-select: none;
    cursor: pointer;
}
.main-container
    div.right
    div.tab-content
    div.tab-body
    div.svgColorAmounts
    .amount-btn:where(:hover, :focus, .selected) {
    background-color: #251d3a;
    transition: background-color 600ms ease-out, color 200ms linear;
    color: white;
}
.main-container div.right div.tab-content div.tab-body div.svgColorAmounts .amount-btn img {
    width: 100%;
    height: 40px;
    object-fit: contain;
}

.main-container div.right div.tab-content div.tab-body div.color-list div.color {
    margin-top: 1rem;
    padding: 10px;
}
.main-container div.right div.tab-content div.tab-body div.color-list div.color h4 {
    margin-bottom: 1.5rem;
}
.main-container div.right div.tab-content div.tab-body div.color-list div.color .color-input {
    display: flex;
    align-items: center;
}
.main-container div.right div.tab-content div.tab-body div.color-list div.color .color-input span {
    padding-right: 1rem;
}
.main-container div.right div.tab-content div.tab-body div.color-list div.color .color-input input {
    width: 150px;
}

.main-container div.right div.tab-content div.tab-body div.choose-images .single-image + .single-image {
    margin-top: 2rem;
}

.main-container div.right div.tab-content div.tab-body div.choose-images .single-image > div {
    display: flex;
    justify-content: center;
    padding: 5px;
}
.main-container div.right div.tab-content div.tab-body div.choose-images .single-image input[type="file"] {
    display: none;
}
.main-container div.right div.tab-content div.tab-body div.choose-images .single-image label {
    padding: 1rem 2rem;
    background-color: rgb(255, 30, 90);
    color: white;
}

.main-container div.right div.tab-content div.tab-body .text-input {
    display: flex;
    flex-direction: column;
    padding: 5px;
}
.main-container div.right div.tab-content div.tab-body .text-input input[type="text"],
.main-container div.right div.tab-content div.tab-body .text-input select {
    padding: 8px 2px;
    margin-top: 5px;
}
.main-container div.right div.tab-content div.tab-body .text-input input[type="color"] {
    width: 100%;
    margin-top: 10px;
}
.main-container div.right div.tab-content div.tab-body .text-family {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
}
.main-container div.right div.tab-content div.tab-body .text-family .font-family-btn {
    user-select: none;
    text-align: center;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    width: calc(50% - 30px);
    border: 1px solid lightblue;
    margin: 5px;
    padding: 5px;
    aspect-ratio: 1/1;
}
.main-container div.right div.tab-content div.tab-body .text-family .font-family-btn.selected {
    background-color: #251d3a;
    color: white;
}
.main-container div.right div.tab-content div.tab-body .text-family .font-family-btn:where(:hover, :focus) {
    transition: background-color 600ms ease-out, color 200ms linear;
    background-color: #251d3a;
    color: white;
}

.main-container div.center {
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
}

.main-container div.center #canvas {
    width: calc(100% - 4rem);
    height: auto;
    aspect-ratio: 800/200;
    background-color: #e2eaf4;
}

.main-container div.right .text .text-position-picker {
    display: flex;
    padding: 0.5rem;
    width: 100%;
    aspect-ratio: 1/1;
    position: relative;
}

.main-container div.right .text .text-position-picker input[type="range"] {
    position: absolute;
    width: calc(100% - 1rem);
    background-color: transparent;
    -webkit-appearance: none;
}
.main-container div.right .text .text-position-picker input[type="range"]:first-of-type {
    top: 50%;
}
.main-container div.right .text .text-position-picker input[type="range"]:last-of-type {
    transform: rotate(90deg);
    top: 50%;
}
.main-container div.right .text .text-position-picker input[type="range"]:focus {
    outline: none;
}
.main-container div.right .text .text-position-picker input[type="range"]::-webkit-slider-runnable-track {
    background: rgba(198, 213, 234, 0.4);
    border: 0px solid rgba(0, 0, 0, 0);
    border: 0;
    width: 100%;
    height: 10px;
    cursor: pointer;
}
.main-container div.right .text .text-position-picker input[type="range"]::-webkit-slider-thumb {
    margin-top: -2.5px;
    width: 15px;
    height: 15px;
    background: #0075ff;
    border: 2.5px solid rgba(0, 0, 0, 0);
    border-radius: 12px;
    cursor: pointer;
    -webkit-appearance: none;
}
.main-container div.right .text .text-position-picker input[type="range"]:focus::-webkit-slider-runnable-track {
    background: #d9e3f1;
}
.main-container div.right .text .text-position-picker input[type="range"]::-moz-range-track {
    background: rgba(198, 213, 234, 0.4);
    border: 0px solid rgba(0, 0, 0, 0);
    border: 0;
    width: 100%;
    height: 10px;
    cursor: pointer;
}
.main-container div.right .text .text-position-picker input[type="range"]::-moz-range-thumb {
    width: 15px;
    height: 15px;
    background: #0075ff;
    border: 2.5px solid rgba(0, 0, 0, 0);
    border-radius: 12px;
    cursor: pointer;
}
.main-container div.right .text .text-position-picker input[type="range"]::-ms-track {
    background: transparent;
    border-color: transparent;
    border-width: 5.1px 0;
    color: transparent;
    width: 100%;
    height: 10px;
    cursor: pointer;
}
.main-container div.right .text .text-position-picker input[type="range"]::-ms-fill-lower {
    background: #b3c7e3;
    border: 0px solid rgba(0, 0, 0, 0);
    border: 0;
}
.main-container div.right .text .text-position-picker input[type="range"]::-ms-fill-upper {
    background: rgba(198, 213, 234, 0.4);
    border: 0px solid rgba(0, 0, 0, 0);
    border: 0;
}
.main-container div.right .text .text-position-picker input[type="range"]::-ms-thumb {
    width: 15px;
    height: 15px;
    background: #0075ff;
    border: 2.5px solid rgba(0, 0, 0, 0);
    border-radius: 12px;
    cursor: pointer;
    margin-top: 0px;
    /*Needed to keep the Edge thumb centred*/
}
.main-container div.right .text .text-position-picker input[type="range"]:focus::-ms-fill-lower {
    background: rgba(198, 213, 234, 0.4);
}
.main-container div.right .text .text-position-picker input[type="range"]:focus::-ms-fill-upper {
    background: #d9e3f1;
}

@media screen and (min-width: 871px) {
    .main-container div.left {
        height: 100vh;
        min-height: 400px;
    }
    .main-container div.center {
        height: 100vh;
        min-height: 400px;
    }
}

@media screen and (max-width: 870px) {
    .main-container {
        grid-template-columns: 1fr 10fr;
        gap: 2rem;
    }
    .main-container div.right-container {
        grid-column: 1/3;
    }
    .main-container .center #canvas {
        width: 100% !important;
        height: auto !important;
        aspect-ratio: 800 / 200;
    }
    .main-container div.left {
        padding-left: 0.5rem;
    }

    .main-container div.center {
        padding-right: 0.5rem;
    }
}
@media screen and (max-width: 500px) {
    .main-container {
        grid-template-columns: none;
        grid-template-rows: 1fr 1fr 5fr;
    }
    .main-container div.right-container {
        grid-column: initial;
    }
    .main-container div.left {
        flex-direction: row !important;
        align-items: center;
        padding-inline: 0.5rem;
    }
    .main-container .center {
        height: max-content;
    }
    .main-container div.left > div + div {
        margin-top: 0rem;
        margin-left: 1rem;
    }
    .main-container div.center {
        padding-inline: 0.5rem;
    }
}
